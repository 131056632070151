import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setCreditData: ["creditData"],
  startCreditCreate: ["data"],
  getTrackingACC: ["id"],
  downloadAmortizationTable: ["id"],
  urlDownload: ["urlDownload"],
  eraseData:[""],
});

export const AccTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  data: "",
  campaingData: "",
  response: "",
  creditData: {},
};

const setCreditData = (state, { creditData }) =>
  Object.assign({}, state, {
    isFetching: false,
    creditData,
  });
  const urlDownload = (state, { urlDownload }) =>
    Object.assign({}, state, {
      isFetching: false,
      urlDownload,
    });
const startCreditCreate = (state) =>
  Object.assign({}, state, {
    isFetching: true,
    response: "",
  });

const getTrackingACC = (state) =>
  Object.assign({}, state, {
    isFetching: true,
    response: "",
  });

const downloadAmortizationTable = (state) =>
  Object.assign({}, state, {
    isFetching: true,
    response: "",
  });

  const eraseData = state =>   
    Object.assign({}, state, {
      creditData: {},
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CREDIT_DATA]: setCreditData,
  [Types.URL_DOWNLOAD]: urlDownload,
  [Types.START_CREDIT_CREATE]: startCreditCreate,
  [Types.GET_TRACKING_ACC]: getTrackingACC,
  [Types.DOWNLOAD_AMORTIZATION_TABLE]: downloadAmortizationTable,
  [Types.ERASE_DATA]: eraseData
});
