import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app.js";
import LoginActions, { LoginTypes } from "../reducers/login.reducer";
import { LoginSerializer } from "../../lib/serializers/login.serializer";

const loginRedirectURL = state => {
  switch (state) {
    case "campaign":
      return "/campaigns";
    case "company":
      return "/companies";
    default:
      return "/";
  }
};

const redirectErrorURL = (statusCode) => {
  switch (true) {
    case statusCode === 403:
      return "/oops";
    case statusCode === 410:
      return "/oops";
    default:
      return "login";
  }
};

export const logout = action$ => 
  action$.pipe(
    ofType(LoginTypes.SUBMIT_LOGOUT),
    flatMap(()=>{
      return Observable.of(
        push(`/login`)
      );
    })
  )

export const prueba = action$ => 
  action$.pipe(
    ofType(LoginTypes.PRUEBA),
    flatMap(()=>{
      return Observable.from(
        App.api.a2censo.apiGateway()
      ).pipe(
        flatMap((response) => {
          console.log(response)
        }),
        catchError(response => {
          console.log(response)
        })
      );
    })
  )


export const loginSubmit = action$ =>
  action$.pipe(
    ofType(LoginTypes.SUBMIT_LOGIN),
    flatMap(({ credentials: { email, password, userIp } }) => {
      return Observable.from(
        App.api.a2censo.login({
          body: LoginSerializer().serialize({ email, password, userIp})
        })
      ).pipe(
        flatMap(({ access_token: accessToken, id_token: idToken, groups, aws_credentials: awsCredentials, funcionalities_role: funcionalitiesRole, more_info: moreInfo }) => {
          return Observable.of(
            LoginActions.loginSuccess({ accessToken, idToken, groups, email, awsCredentials, funcionalitiesRole, moreInfo }),
            push(loginRedirectURL(groups[0]))
          );
        }),
        catchError(response => {
          return Observable.concat(
            Observable.of(
              LoginActions.loginFail({
                error: response.statusCode === 401 ? "401" : "403",
              })
            ),
            Observable.of(
              push(redirectErrorURL(response.statusCode), {})
            )
          );
        })
      );
    })
  );
export default combineEpics(loginSubmit, logout, prueba);
