const theme = {
  brightTurquoise: "#0bbbef",
  brightTurquoiseTransparent: "rgba(11, 187, 239, 0.3)",
  // bright-turquoise-transparent: #cfecf6,
  congressBlue: "#004997",
  tuatara: "#31302f",
  springGreen: "#39fbbf",
  cornflowerBlue: "#6262f9",
  transparentGray: "rgba(200, 200, 201, 0.39)",
  transparentWhite: "rgba(255, 255, 255, 0.6)",
  middleGray: "rgb(166, 166, 169)",
  gray: "#f5f6f8",
  darkGray: "#babdca",
  white: "#ffffff",
  brightBlue: "#ddf1f8",
  red: "#E11A2C",
  smartphone: "@media screen and (max-width: 480px) ",
  tablet: "@media screen and (max-width: 768px) ",
  desktop: "@media screen and (max-width: 1024px) ",
  respondesktopsiveDesktop: "@media screen and (max-width: 990px) ",
  altoTwo: "#CECECE",
  lightPurple: "#2C5697"
};

export default theme;
