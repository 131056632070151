import { AccIntegrationConnect } from "../../../services/acc-integration.js";
import AccActions from "../../reducers/integration/acc.reducer.js";
import LoginActions from "../../reducers/login.reducer";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import { catchError, flatMap } from "rxjs/operators";
import { push } from "connected-react-router";
import  { openFile}  from "../../../lib/utils/downloadFile";

export const creditCreation = (action$) =>
  action$.pipe(
    ofType("START_CREDIT_CREATE"),
    flatMap((action) => {
      return Observable.from(
        new AccIntegrationConnect()
          .creditCreation(action.data)
          .then((res) => res)
      ).pipe(
        flatMap((response) => {
          return Observable.of(AccActions.setCreditData(response));
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.of(push(`/oops`));
          }
        })
      );
    })
  );

export const getTrackingACC = (action$) =>
  action$.pipe(
    ofType("GET_TRACKING_ACC"),
    flatMap((action) => {
      return Observable.from(
        new AccIntegrationConnect().getTrackingACC(action.id).then((res) => res)
      ).pipe(
        flatMap((response) => {
          return Observable.of(AccActions.setCreditData(response));
        }),
        catchError((response) => {
          if (response.statusCode === 401) {
            return Observable.of(LoginActions.submitLogout());
          } else {
            return Observable.of(push(`/oops`));
          }
        })
      );
    })
  );

export const downloadAmortizationTable = (action$) =>
action$.pipe(
  ofType("DOWNLOAD_AMORTIZATION_TABLE"),
  flatMap((action) => { 
    return Observable.from(
      new AccIntegrationConnect().downloadTA(action.id).then((res) => res)
    ).pipe(
      flatMap((response) => {
        openFile(response.url,"");
        return Observable.of(AccActions.urlDownload(response));

      }),
      catchError((response) => {
        if (response.statusCode === 401) {
          return Observable.of(LoginActions.submitLogout());
        } else {
          return Observable.of(push(`/oops`));
        }
      })
    );
  })
);  

export default combineEpics(creditCreation, getTrackingACC, downloadAmortizationTable);
