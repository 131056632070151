export const directDownloadFile = async (file, name) => {
  const blob = await fetch(file, {
    method: "GET"
  }).then((response) => response.blob());
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  const ext = file
    .split("/")
    .pop()
    .split("#")[0]
    .split("?")[0]
    .split(".")[1];
  link.setAttribute("download", `${name.replace(/(\s)/g, "-")}.${ext}`);
  link.click();
  link.parentNode.removeChild(link);
};
 export const openFile = (file, openInNewWindow = true) => {
  const elmA = document.createElement("a");
  elmA.style.display = "none";
  if (openInNewWindow) {
    elmA.setAttribute("target", "_blank");
  }
  elmA.setAttribute("href", file);
  elmA.click();
};



